import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  getListOrgDetailApi,
  MergeOrgDetail,
  searchAllApi,
  SearchAllRequest,
} from 'api/salesApi'
import {RootState} from 'store'
import i18n from 'i18n'
import {openFailureModalAction} from 'features/modal/alertSlice'

export const searchCustomerOrg = createAsyncThunk(
  'api/eeg/CustomerOrg/search',
  async (payload: SearchAllRequest, {rejectWithValue, dispatch}) => {
    try {
      const resAll = await searchAllApi(payload)
      const orgIds = resAll.page?.content?.map((item) => item.orgId) ?? []
      if (!resAll.page?.content.length) {
        dispatch(openFailureModalAction(i18n.t('ISearchORGEmpty')))
        return {...resAll.page, content: []}
      }
      const resDetails = await getListOrgDetailApi({orgId: orgIds})
      const data: MergeOrgDetail[] = resAll.page.content.map((item) => {
        const matchedDetail = resDetails.list.find(
          (detail) => detail?.org?.id === item.orgId,
        )
        return {
          ...matchedDetail,
          orgDetail: item,
        }
      })
      return {...resAll.page, content: data}
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

interface CustomerRegistrationOrgState {
  loading: boolean
  error: any
  items: MergeOrgDetail[]
  listOrg: MergeOrgDetail[]
  orgSelected: MergeOrgDetail | null
  search: SearchAllRequest
  paging: PagingV4
  oid?: string | null
  pagingInfo: {
    totalPages: number
    totalElements: number
  }
  reSelectYn: YN | undefined
  reAnalysisYn: YN | undefined
  isIcaSelectOpen: boolean
  isIcaConfirmOpen: boolean
  isIcaResultOpen: boolean
  sraYn?: YN | undefined
}

const initialState: CustomerRegistrationOrgState = {
  loading: false,
  error: null,
  items: [],
  listOrg: [],
  orgSelected: null,
  search: {},
  reSelectYn: undefined,
  reAnalysisYn: undefined,
  isIcaSelectOpen: false,
  isIcaConfirmOpen: false,
  isIcaResultOpen: false,
  oid: '',
  sraYn: undefined,
  paging: {number: 0, size: 10},
  pagingInfo: {
    totalPages: 1,
    totalElements: 0,
  },
}

export const customerOrgSlice = createSlice({
  name: 'customerRegistrationOrgSlice',
  initialState,
  reducers: {
    setListOrg: (state, action) => {
      state.listOrg = action.payload
    },
    setOrgSelected: (state, action) => {
      state.orgSelected = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchCustomerOrg.pending, (state, action) => {
      state.loading = true
      state.error = null
      const search = action.meta.arg
      state.search = search
      if (search)
        state.paging = {number: search.page ?? 0, size: search.size ?? 10}
    })
    builder.addCase(searchCustomerOrg.fulfilled, (state, action) => {
      state.loading = false

      const {
        size,
        number,
        totalPages,
        totalElements,
        content: items,
      } = action.payload
      state.items = items
      state.loading = false
      state.error = null
      state.paging.number = number
      state.paging.size = size
      state.pagingInfo.totalPages = totalPages
      state.pagingInfo.totalElements = totalElements
    })
    builder.addCase(searchCustomerOrg.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {setListOrg, setOrgSelected} = customerOrgSlice.actions

export default customerOrgSlice.reducer

export const selectList = (state: RootState) => state.invoiceInquiry.items
export const selectLoading = (state: RootState) => state.invoiceInquiry.loading
export const selectError = (state: RootState) => state.invoiceInquiry.error
export const selectSearch = (state: RootState) => state.invoiceInquiry.search
export const selectListOrg = (state: RootState) => state.invoiceInquiry.listOrg
export const selectPaging = (state: RootState) => state.invoiceInquiry.paging
export const selectPagingInfo = (state: RootState) =>
  state.invoiceInquiry.pagingInfo
export const selectOrgSelected = (state: RootState) =>
  state.invoiceInquiry.orgSelected
export const selectQuery = (state: RootState) => ({
  search: state.invoiceInquiry.search,
  oid: state.invoiceInquiry.oid,
  paging: state.invoiceInquiry.paging,
})
