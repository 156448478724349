import {useAppDispatch, useAppSelector} from 'hooks'
import {openOrgCustomerManagementDialog} from 'features/modal/modalSlice'
import {MergeOrgDetail, SearchAllRequest} from 'api/salesApi'
import {
  searchCustomerOrg,
  selectError,
  selectLoading,
  selectQuery,
  selectSearch,
  selectPaging,
  selectList,
  selectPagingInfo,
  selectOrgSelected,
  setOrgSelected,
  setListOrg,
} from 'features/invoice/invoiceInquirySlice'

export default function useCustomerOrg() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const items = useAppSelector(selectList)
  const paging = useAppSelector(selectPaging)
  const search = useAppSelector(selectSearch)
  const query = useAppSelector(selectQuery)
  const pagingInfo = useAppSelector(selectPagingInfo)
  const orgSelected = useAppSelector(selectOrgSelected)
  const initOrgSelected = null
  const onSearch = async (payload: SearchAllRequest) => {
    dispatch(searchCustomerOrg(payload))
  }

  const changeSelectedOrg = (listOrg: MergeOrgDetail[]) => {
    if (listOrg.length > 1) dispatch(openOrgCustomerManagementDialog())
    else if (listOrg.length === 1) dispatch(setOrgSelected(listOrg[0]))
    else dispatch(setOrgSelected(initOrgSelected))
  }

  const resetListOrg = () => {
    dispatch(setListOrg([]))
    dispatch(setOrgSelected(initOrgSelected))
  }

  return {
    loading,
    error,
    items,
    search,
    paging,
    query,
    pagingInfo,
    orgSelected,
    onSearch,
    changeSelectedOrg,
    resetListOrg,
  }
}
