import DateFnsUtils from '@date-io/date-fns'
import {yupResolver} from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import Card from 'components/atoms/Card'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import SelectBoldCountry from 'components/Dialog/BoldSelect/SelectBoldCountry'
import SelectBoldIndustryWrapper from 'components/Dialog/BoldSelect/SelectBoldIndustry'
import SuccessDialog from 'components/Dialog/SuccessDialog'
import {nullCheck} from 'components/Dialog/User/Read'
import useStyles from 'components/Dialog/User/Style'
import {
  A_CHARGE_PROPERTY_TYPE,
  COMPANY_PROPERTY_TYPE,
  MOU_PROPERTY_TYPE,
  NO_CHARGE_PROPERTY_TYPE,
  NONE_PROPERTY_TYPE,
  ORG_ADMIN_INFO_DIALOG_TYPE,
  ORG_INFO_DIALOG_TYPE,
} from 'constants/DialogConstant'
import useAuth from 'features/auth/useAuth'
import {
  closeOrgDetailInfoEditDialog,
  selectEditOrgDetailDialogOpen,
  selectOrgDetailDialogType,
  selectOrgDetailUId,
} from 'features/modal/modalSlice'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {selectData as selectOrganization} from 'features/org/orgSlice'
import useOrganization from 'features/org/useOrganization'
import {
  fetchSalesDetailAction,
  selectSales,
  selectSalesDetailInfo,
  updateSalesOrganizationRequest,
} from 'features/sales/salesSlice'
import {formatDate} from 'helpers/dateHelper'
import {useAppSelector} from 'hooks'
import React, {useEffect} from 'react'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {object, ref, string, TypeOf} from 'yup'
import {PASSWORD_SCHEME, REGEX_PHONE} from 'constants/CommonConstant'
import SelectBoldCountryCode from '../../BoldSelect/SelectBoldCountryCode'
import SelectSalesName from './SelectSalesName'

// 서버로 보낼때 사용하는 type
interface OrgInfoEditInputType {
  uid: string
  email?: string | null
  orgName?: string | null
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  countryCode?: string | null
  userType?: string | null
  profile?: File | null
  tel?: string | null
  area?: number | null
  address?: string | null
  businessNo?: string | null
  institutionNo?: string | null
  doctorCount?: number | null
  joinRoute?: string | null
  interestArea?: number | null
  password?: string | null
  passwordConfirm?: string | null
  salesName?: string | null
  authorityGroup?: string | null
  property?: Array<string> | null
  startDate?: string | null
  endDate?: string | null
  industry?: Industry
  detailIndustry?: Industry
}

export default function OrganizationEditDialog() {
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const dispatch = useDispatch()
  const {t, i18n} = useTranslation()
  const lang = i18n.language
  const open = useAppSelector(selectEditOrgDetailDialogOpen)
  const uid = useAppSelector(selectOrgDetailUId)
  const dialogType = useAppSelector(selectOrgDetailDialogType)
  const registeredUserUid = useAppSelector(selectSales)
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()

  const {user: currentUser} = useAuth()
  const {onFetch: onOrganizationFetch, onUpdate: onOrganizationUpdate} =
    useOrganization()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()

  const orgDetailInfo = useAppSelector(
    dialogType === ORG_ADMIN_INFO_DIALOG_TYPE
      ? selectSalesDetailInfo
      : selectOrganization,
  )

  interface EditForm extends TypeOf<typeof AddSchema> {}

  const AddSchema = object({
    orgName: string().min(1, t('IRegisterHospitalName')),
    fullName: string().min(1),
    area: string().min(1, t('IRegisterArea')),
    address: string().min(1, t('IRegisterIAddress')),
    businessNo: string(),
    password: PASSWORD_SCHEME,
    passwordConfirm: string().oneOf(
      [ref('password'), null],
      'IPasswordSamePlease',
    ),
    tel: string().matches(REGEX_PHONE, t('ITelInvalid')),
    firstName: string().min(1, t('INameRequired')),
    lastName: string().min(1, t('INameRequired')),
    countryCode: string(),
    institutionNo: string(),
    doctorCount: string(),
    interestArea: string(),
    joinRoute: string(),
    salesName: string(),
    authorityGroup: string(),
  })
  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm<EditForm>({
    resolver: yupResolver(AddSchema),
  })

  // 전화번호 - 제거
  const replaceValue = orgDetailInfo?.tel ?? ''
  const telValue = replaceValue.replace(/-/gi, '')

  const [industryId, setIndustryId] = React.useState<Industry>({
    id: 0,
    parent: 0,
    depth: 0,
    title: '',
  })
  const [detailIndustryId, setDetailIndustryId] = React.useState<Industry>({
    id: 0,
    parent: 0,
    depth: 0,
    title: '',
  })
  const handleIndustryChanged = (industry: Industry) => {
    setIndustryId(industry)
    setDetailIndustryId({
      id: undefined,
      parent: 0,
      depth: 0,
      title: '',
    })
  }
  const handleDetailIndustryChanged = (industry: Industry) =>
    setDetailIndustryId(industry)

  const [authorityGroup, setAuthorityGroup] = React.useState(false)

  const [startDate, setStartDate] = React.useState<Date>()
  const [endDate, setEndDate] = React.useState<Date>()
  const handleStartDateChanged = (date: Date | null) => {
    setStartDate(date ?? new Date())
  }
  const handleEndDateChanged = (date: Date | null) =>
    setEndDate(date ?? new Date())

  /**
   * 속성 관련
   */
  const [propertyState, setPropertyState] = React.useState({
    MOU: false,
    COMPANY: false,
    A_CHARGE: false,
    NO_CHARGE: false,
  })

  // 서버로 보낼 속성 arrayList
  const propertyValueList: string[] = []

  // 서버로 보낼 array value 작성
  const sendPropertyValue = () => {
    if (propertyState.MOU) {
      propertyValueList.push(MOU_PROPERTY_TYPE)
    }
    if (propertyState.COMPANY) {
      propertyValueList.push(COMPANY_PROPERTY_TYPE)
    }
    if (propertyState.A_CHARGE) {
      propertyValueList.push(A_CHARGE_PROPERTY_TYPE)
    }
    if (propertyState.NO_CHARGE) {
      propertyValueList.push(NO_CHARGE_PROPERTY_TYPE)
    }
    if (
      !propertyState.MOU &&
      !propertyState.COMPANY &&
      !propertyState.A_CHARGE &&
      !propertyState.NO_CHARGE
    ) {
      propertyValueList.push(NONE_PROPERTY_TYPE)
    }
    return true
  }

  const handlePropertyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPropertyState({
      ...propertyState,
      [event.target.value as string]: event.target.checked,
    })
  }

  const handleDialogClose = () => {
    dispatch(closeOrgDetailInfoEditDialog())
    reset()
  }

  const onSubmit = async (data: OrgInfoEditInputType) => {
    if (dialogType === ORG_ADMIN_INFO_DIALOG_TYPE) {
      data.uid = registeredUserUid.searchedDetailData.userInfoList[0].uid
      data.email = orgDetailInfo?.email ?? ''
      if (sendPropertyValue()) {
        data.property = propertyValueList
      }
      if (startDate && data.authorityGroup === 'SHAREWARE_GROUP') {
        data.startDate = formatDate(startDate)
      }
      if (endDate && data.authorityGroup === 'SHAREWARE_GROUP') {
        data.endDate = formatDate(endDate)
      }
    } else if (dialogType === ORG_INFO_DIALOG_TYPE) {
      data.uid = uid
      data.industry = industryId
      data.detailIndustry = detailIndustryId
      delete data.authorityGroup
    }

    // submit object에서 undefined인 값 삭제
    const submitOrgInfo = Object.fromEntries(
      // eslint-disable-next-line
      Object.entries(data).filter(([_, v]) => v != null),
    )

    // Update 2 properties businessNo and institutionNo at the same time
    if (data.businessNo === undefined && data.institutionNo === undefined) {
      submitOrgInfo.institutionNo = orgDetailInfo?.institutionNo
      submitOrgInfo.businessNo = orgDetailInfo?.businessNo
    } else if (
      data.businessNo === undefined ||
      data.institutionNo === undefined
    ) {
      if (data.businessNo !== undefined) {
        submitOrgInfo.institutionNo = orgDetailInfo?.institutionNo
      }
      if (data.institutionNo !== undefined) {
        submitOrgInfo.businessNo = orgDetailInfo?.businessNo
      }
    }

    if (data.passwordConfirm) {
      delete submitOrgInfo.passwordConfirm
    }
    if (dialogType === ORG_INFO_DIALOG_TYPE) {
      try {
        await onOrganizationUpdate(submitOrgInfo)
        await onOrganizationFetch()
        onSuccessModalOpen(t('IMessageOperationSuccess')).then(() => {
          handleDialogClose()
        })
        reset()
      } catch (err) {
        onFailureModalOpen(err.message)
      }
    }
    if (dialogType === ORG_ADMIN_INFO_DIALOG_TYPE) {
      dispatch(updateSalesOrganizationRequest())
      dispatch(
        fetchSalesDetailAction({
          uid: currentUser?.uid ?? '',
          oid: registeredUserUid.searchedData.oid ?? '',
        }),
      )
      onSuccessModalOpen(t('IMessageOperationSuccess')).then(() => {
        handleDialogClose()
      })
      reset()
    }
  }

  const onSubmitHandler: SubmitHandler<EditForm> = (data) =>
    onSubmit(data as OrgInfoEditInputType)

  // 병원정보 속성 셋팅
  useEffect(() => {
    const orgPropertyArray = orgDetailInfo?.property
    if (orgPropertyArray) {
      setPropertyState({
        ...propertyState,
        [NO_CHARGE_PROPERTY_TYPE]: orgPropertyArray.includes(
          NO_CHARGE_PROPERTY_TYPE,
        ),
        [MOU_PROPERTY_TYPE]: orgPropertyArray.includes(MOU_PROPERTY_TYPE),
        [A_CHARGE_PROPERTY_TYPE]: orgPropertyArray.includes(
          A_CHARGE_PROPERTY_TYPE,
        ),
        [COMPANY_PROPERTY_TYPE]: orgPropertyArray.includes(
          COMPANY_PROPERTY_TYPE,
        ),
      })
    }
  }, [orgDetailInfo])

  // 병원정보 권한 셋팅
  useEffect(() => {
    if (orgDetailInfo?.authorityGroup === 'SHAREWARE_GROUP') {
      setAuthorityGroup(true)
    } else {
      setAuthorityGroup(false)
    }
    if (orgDetailInfo?.authorityGroup === 'SHAREWARE_GROUP') {
      const startDateValue = new Date(orgDetailInfo?.startDate)
      setStartDate(startDateValue)
      const endDateValue = new Date(orgDetailInfo?.endDate)
      setEndDate(endDateValue)
    }
    setIndustryId(
      orgDetailInfo?.industry ?? {
        id: 0,
        parent: 0,
        depth: 0,
        title: '',
      },
    )
    setDetailIndustryId(
      orgDetailInfo?.detailIndustry ?? {
        id: 0,
        parent: 0,
        depth: 0,
        title: '',
      },
    )
  }, [orgDetailInfo])

  useEffect(() => {
    if (open && uid && dialogType === ORG_INFO_DIALOG_TYPE) {
      onOrganizationFetch()
    }
    if (dialogType === ORG_ADMIN_INFO_DIALOG_TYPE) {
      dispatch(
        fetchSalesDetailAction({
          uid: currentUser?.uid ?? '',
          oid: registeredUserUid.searchedData.oid ?? '',
        }),
      )
    }
  }, [open && uid])

  useEffect(() => {
    console.log({errors})
  }, [errors])

  return (
    <Dialog open={open} fullWidth maxWidth='md'>
      <SuccessDialog />
      <Card className={classes.cardWrap}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className={classes.root}>
            {/* 상단 타이틀 컨테이너  */}
            <div className={classes.longTitleContainer}>
              <div className={classes.containerTitle}>
                <div className={classes.closeButtonWrap}>
                  <IconButton
                    color='secondary'
                    aria-label='favorite'
                    className={classes.closeButton}
                    onClick={() => {
                      onNoticeDialogOpen({
                        title: '',
                        message: t('ICancleConfirm'),
                      }).then((result) => {
                        if (result.payload) {
                          handleDialogClose()
                        }
                      })
                    }}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </div>
                <Typography variant='h6' className={classes.title}>
                  {t('IOrgEdit')}
                </Typography>
              </div>
            </div>
            <Divider className={classes.titleDivider} />
            <Typography className={classes.padding} />

            <div className={classes.scroll}>
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}> {t('IOrgName')} </p>
                  <p className={inputClasses.require}>*</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Controller
                    name='orgName'
                    control={control}
                    render={({field}) => {
                      return (
                        <input
                          className={inputClasses.input}
                          placeholder={t('INameRequired')}
                          defaultValue={orgDetailInfo?.orgName ?? '-'}
                          {...field}
                        />
                      )
                    }}
                  />
                </div>
                <span className={classes.errorText}>
                  {errors.orgName && (
                    <div>{t(errors.orgName?.message ?? '')}</div>
                  )}
                </span>
              </div>
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('ICeoName')}</p>
                  <p className={inputClasses.require}>*</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {lang !== 'ko' && (
                    <>
                      <Controller
                        name='firstName'
                        control={control}
                        rules={{required: true}}
                        render={({field}) => (
                          <input
                            className={inputClasses.input}
                            defaultValue={orgDetailInfo?.firstName ?? '-'}
                            {...field}
                            disabled
                          />
                        )}
                      />
                      <Typography className={classes.padding} />
                      <Controller
                        name='lastName'
                        control={control}
                        rules={{required: true}}
                        render={({field}) => (
                          <input
                            className={inputClasses.input}
                            defaultValue={orgDetailInfo?.lastName ?? '-'}
                            {...field}
                            disabled
                          />
                        )}
                      />
                    </>
                  )}
                  {lang === 'ko' && (
                    <>
                      <Controller
                        name='lastName'
                        control={control}
                        rules={{required: true}}
                        render={({field}) => (
                          <input
                            className={inputClasses.input}
                            defaultValue={orgDetailInfo?.lastName ?? '-'}
                            {...field}
                            disabled
                          />
                        )}
                      />
                      <Typography className={classes.padding} />
                      <Controller
                        name='firstName'
                        control={control}
                        rules={{required: true}}
                        render={({field}) => (
                          <input
                            className={inputClasses.input}
                            defaultValue={orgDetailInfo?.firstName ?? '-'}
                            {...field}
                            disabled
                          />
                        )}
                      />
                    </>
                  )}
                </div>
                <span className={classes.errorText}>
                  {errors.firstName && !errors.lastName && (
                    <div>{t(errors.firstName?.message ?? '')}</div>
                  )}
                  {errors.lastName && !errors.firstName && (
                    <div>{t(errors.lastName?.message ?? '')}</div>
                  )}
                  {errors.firstName && errors.lastName && (
                    <div>{t(errors.lastName?.message ?? '')}</div>
                  )}
                </span>
              </div>
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('IEmail')}</p>
                  <p className={inputClasses.require}>*</p>
                </div>

                <input
                  className={inputClasses.input}
                  defaultValue={orgDetailInfo?.email ?? ''}
                  disabled
                />
              </div>
              <Divider className={classes.line} />
              {/* HOTFIX: 내정보에서도 비밀번호 변경이 되는 관계로 
              중복기능이라 가려놓았지만 추후 추가할 가능성이 있기 때문에 주석처리 해놓음. */}
              {/* {dialogType === ORG_INFO_DIALOG_TYPE && (
                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>
                      {t('IPasswordPrevious')}
                    </p>
                    <p className={inputClasses.require}>*</p>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <input
                      id='org_name'
                      placeholder={t('IPassword')}
                      type='password'
                      className={inputClasses.input}
                      onChange={(e) => setPassword(e.target.value as string)}
                    />
                    <Typography className={classes.padding} />
                    <ActionButton
                      // variant='contained'
                      color='primary'
                      disableElevation
                      className={classes.editButton}
                      onClick={handlePasswordConfirm}
                    >
                      {t('IEdit')}
                    </ActionButton>
                  </div>
                </div>
              )} */}
              {/* {dialogType === ORG_INFO_DIALOG_TYPE && (
                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>
                      {t('IPasswordNext')}
                    </p>
                    <p className={inputClasses.require}>*</p>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Controller
                      name='password'
                      control={control}
                      rules={{required: false}}
                      render={({field}) => (
                        <input
                          placeholder={t('IPasswordRequired')}
                          type='password'
                          disabled={disable}
                          className={inputClasses.input}
                          {...field}
                        />
                      )}
                    />
                    <Typography className={classes.padding} />
                    <Controller
                      name='passwordConfirm'
                      control={control}
                      rules={{required: false}}
                      render={({field}) => (
                        <input
                          placeholder={t('IPasswordSamePlease')}
                          type='password'
                          disabled={disable}
                          className={inputClasses.input}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <span className={classes.errorText}>
                    {errors.password && (
                      <div>{t(errors.password?.message ?? '')}</div>
                    )}
                  </span>
                  <span className={classes.errorText}>
                    {!errors.password && errors.passwordConfirm && (
                      <div>{t(errors.passwordConfirm?.message ?? '')}</div>
                    )}
                  </span>
                </div>
              )} */}
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('ICountry')}</p>
                  <p className={inputClasses.require}>*</p>
                </div>

                {nullCheck(orgDetailInfo?.country) && (
                  <Controller
                    name='countryCode'
                    control={control}
                    // defaultValue={orgDetailInfo?.country.code ?? 'KR'}
                    render={({field}) => (
                      <SelectBoldCountryCode
                        defaultCountry={orgDetailInfo?.country}
                        // className={classes.selectContainer}
                        // className={classes.selectBox}
                        error={!!errors.countryCode}
                        {...field}
                      />
                    )}
                  />
                )}
                {!nullCheck(orgDetailInfo?.country) && (
                  <Controller
                    name='countryCode'
                    control={control}
                    defaultValue='kr'
                    render={({field}) => (
                      <SelectBoldCountry
                        // className={classes.selectBox}
                        error={!!errors.countryCode}
                        {...field}
                      />
                    )}
                  />
                )}
              </div>

              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('ITel')}</p>
                  <p className={inputClasses.require}>*</p>
                </div>

                <Controller
                  name='tel'
                  control={control}
                  rules={{required: false}}
                  render={({field}) => (
                    <input
                      defaultValue={telValue}
                      className={inputClasses.input}
                      // error={!!errors.tel}
                      {...field}
                    />
                  )}
                />
                <span className={classes.errorText}>
                  {errors.tel && <div>{t(errors.tel?.message ?? '')}</div>}
                </span>
              </div>

              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>
                    {t('IIndustryDetail')}
                  </p>
                  <p className={inputClasses.require}>*</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <SelectBoldIndustryWrapper
                    parent={0}
                    depth={1}
                    selectedValue={t('ISelectRequired')}
                    onChangeData={handleIndustryChanged}
                    defaultValue={orgDetailInfo?.industry.id ?? 0}
                  />
                  <Typography className={classes.padding} />
                  <SelectBoldIndustryWrapper
                    parent={industryId.id}
                    depth={2}
                    selectedValue={t('ISelectRequired')}
                    onChangeData={handleDetailIndustryChanged}
                    defaultValue={detailIndustryId.id}
                  />
                </div>
              </div>
              {/* <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('IArea')}</p>
                  <p className={inputClasses.require}>*</p>
                </div>

                <Controller
                  name='area'
                  control={control}
                  rules={{required: false}}
                  render={({field}) => (
                    <input
                      className={inputClasses.input}
                      defaultValue={orgDetailInfo?.area ?? ''}
                      {...field}
                    />
                  )}
                />
                <span className={classes.errorText}>
                  {errors.area && <div>{t(errors.area?.message ?? '')}</div>}
                </span>
              </div> */}

              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('IAddress')}</p>
                  <p className={inputClasses.require}>*</p>
                </div>

                <Controller
                  name='address'
                  control={control}
                  rules={{required: false}}
                  render={({field}) => (
                    <input
                      className={inputClasses.input}
                      defaultValue={orgDetailInfo?.address ?? ''}
                      {...field}
                    />
                  )}
                />
                <span className={classes.errorText}>
                  {errors.address && (
                    <div>{t(errors.address?.message ?? '')}</div>
                  )}
                </span>
              </div>

              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('IBusinessNo')}</p>
                </div>

                <Controller
                  name='businessNo'
                  control={control}
                  rules={{required: false}}
                  render={({field}) => (
                    <input
                      className={inputClasses.input}
                      defaultValue={orgDetailInfo?.businessNo ?? ''}
                      {...field}
                    />
                  )}
                />
                <span className={classes.errorText}>
                  {errors.businessNo && (
                    <div>{t(errors.businessNo?.message ?? '')}</div>
                  )}
                </span>
              </div>

              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>
                    {t('IOrgNursingInstitutionNumber')}
                  </p>
                </div>

                <Controller
                  name='institutionNo'
                  control={control}
                  rules={{required: false}}
                  render={({field}) => (
                    <input
                      className={inputClasses.input}
                      defaultValue={orgDetailInfo?.institutionNo ?? ''}
                      {...field}
                    />
                  )}
                />
                <span className={classes.errorText}>
                  {errors.institutionNo && (
                    <div>{t(errors.institutionNo?.message ?? '')}</div>
                  )}
                </span>
              </div>

              {/* <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>
                    {t('IOrgUserCount')}
                  </p>
                </div>
                <Controller
                  name='doctorCount'
                  control={control}
                  rules={{required: false}}
                  render={({field}) => (
                    <input
                      className={inputClasses.input}
                      defaultValue={orgDetailInfo?.doctorCount ?? ''}
                      {...field}
                    />
                  )}
                />
                <span className={classes.errorText}>
                  {errors.doctorCount && (
                    <div>{t(errors.doctorCount?.message ?? '')}</div>
                  )}
                </span>
              </div>

              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>{t('IJoinRoute')}</p>
                </div>

                <Controller
                  name='joinRoute'
                  control={control}
                  defaultValue={orgDetailInfo?.joinRoute ?? ''}
                  render={({field}) => (
                    <SelectBoldJoinRoute
                      // className={classes.selectBox}
                      // defaultValue={orgDetailInfo?.joinRoute ?? ''}
                      {...field}
                    />
                  )}
                />
              </div>

              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>
                    {t('IInterestArea')}
                  </p>
                </div>
                {orgDetailInfo?.interestArea != null && (
                  <Controller
                    name='interestArea'
                    control={control}
                    defaultValue={orgDetailInfo?.interestArea ?? ''}
                    render={({field}) => (
                      <SelectBoldInterestArea
                        // className={classes.selectBox}
                        {...field}
                      />
                    )}
                  />
                )}
              </div> */}

              {/* 소개 직원 이름 */}
              {dialogType === ORG_ADMIN_INFO_DIALOG_TYPE && (
                <div className={classes.contentContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>
                      {t('ISalesManagerEdit')}
                    </p>
                    <p className={inputClasses.require}>*</p>
                  </div>

                  <Controller
                    name='salesName'
                    control={control}
                    defaultValue={orgDetailInfo?.salesName ?? ''}
                    render={({field}) => <SelectSalesName {...field} />}
                  />
                </div>
              )}

              {dialogType === ORG_ADMIN_INFO_DIALOG_TYPE && (
                <Divider className={classes.line} />
              )}

              {dialogType === ORG_ADMIN_INFO_DIALOG_TYPE && (
                <div className={classes.contentContainer}>
                  <Divider className={classes.line} />
                  <Typography className={classes.requiredMarkHidden}>
                    *
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {t('IAuthority')}
                  </Typography>
                  <Controller
                    name='authorityGroup'
                    control={control}
                    defaultValue={orgDetailInfo?.authorityGroup ?? ''}
                    render={({field}) => (
                      <RadioGroup
                        row
                        defaultValue={orgDetailInfo?.authorityGroup ?? ''}
                        // className={classes.textField}
                        {...field}
                      >
                        <FormControlLabel
                          value='ORGANIZATION_GROUP'
                          control={<Radio color='secondary' />}
                          label={t('IAuthorityForOrg')}
                          onClick={() => setAuthorityGroup(false)}
                        />
                        <FormControlLabel
                          value='SHAREWARE_GROUP'
                          control={<Radio color='secondary' />}
                          label={t('IAuthorityForShareware')}
                          onClick={() => setAuthorityGroup(true)}
                        />
                      </RadioGroup>
                    )}
                  />
                </div>
              )}
              {dialogType === ORG_ADMIN_INFO_DIALOG_TYPE && authorityGroup && (
                <div className={classes.contentContainer}>
                  <Typography className={classes.requiredMarkHidden}>
                    *
                  </Typography>
                  <Typography className={classes.subTitle}> </Typography>
                  {/* 기간검색 */}
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div>
                        <KeyboardDatePicker
                          margin='normal'
                          id='date-picker-dialog'
                          label={t('IStartDate')}
                          format='MM/dd/yyyy'
                          value={startDate}
                          onChange={handleStartDateChanged}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          maxDate={new Date()}
                          inputVariant='outlined'
                          color='primary'
                          style={{flex: 1}}
                        />
                        <Typography className={classes.padding} />
                        <KeyboardDatePicker
                          margin='normal'
                          id='date-picker-dialog'
                          label={t('IEndDate')}
                          format='MM/dd/yyyy'
                          value={endDate}
                          onChange={handleEndDateChanged}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          // maxDate={new Date()}
                          style={{flex: 1}}
                          inputVariant='outlined'
                          color='primary'
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              )}

              {dialogType === ORG_ADMIN_INFO_DIALOG_TYPE && (
                <Divider className={classes.line} />
              )}

              {dialogType === ORG_ADMIN_INFO_DIALOG_TYPE && (
                <div className={classes.contentContainer}>
                  <Divider className={classes.line} />
                  <Typography className={classes.requiredMarkHidden}>
                    *
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {t('IProperty')}
                  </Typography>
                  <FormGroup
                  // className={classes.flexDirection}
                  // defaultValue={MOU_PROPERTY_TYPE}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={propertyState.MOU}
                          onChange={handlePropertyChange}
                          name='Mou'
                          value={MOU_PROPERTY_TYPE}
                        />
                      }
                      label='Mou'
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={propertyState.COMPANY}
                          onChange={handlePropertyChange}
                          name='아이메디신'
                          value={COMPANY_PROPERTY_TYPE}
                        />
                      }
                      label={t('IAppCompany')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={propertyState.A_CHARGE}
                          onChange={handlePropertyChange}
                          name='paid'
                          value={A_CHARGE_PROPERTY_TYPE}
                        />
                      }
                      label={t('IPaid')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={propertyState.NO_CHARGE}
                          onChange={handlePropertyChange}
                          name='free'
                          value={NO_CHARGE_PROPERTY_TYPE}
                        />
                      }
                      label={t('IFree')}
                    />
                  </FormGroup>
                </div>
              )}
              <Typography className={classes.padding} />
            </div>
            {/* 하단 버튼 컨테이너  */}
            <div className={classes.buttonContainerBgColor}>
              <div className={classes.buttonContainer}>
                <Button
                  variant='contained'
                  color='default'
                  disableElevation
                  onClick={() => {
                    onNoticeDialogOpen({
                      title: '',
                      message: t('ICancleConfirm'),
                    }).then((result) => {
                      if (result.payload) {
                        handleDialogClose()
                      }
                    })
                  }}
                  className={classes.okButton}
                >
                  {t('IDiscardEdit')}
                </Button>
                <Typography className={classes.smallPadding} />
                <Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  className={classes.okButtonColor}
                  type='submit'
                >
                  {t('IOk')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </Dialog>
  )
}
