import {useAppDispatch, useAppSelector} from 'hooks'
import {
  fetchDetailOrg,
  selectError,
  selectLoading,
  selectSelected,
} from 'features/invoice/orgRegisterSlice'

export default function useOrgRegister() {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const selected = useAppSelector(selectSelected)

  const onFetch = (payload: {id: number}) => {
    dispatch(fetchDetailOrg(payload))
  }

  return {
    loading,
    error,
    selected,
    onFetch,
  }
}
