import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useAppDispatch, useAppSelector} from 'hooks'
import {object, ref, string, TypeOf} from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import {OrgUserUpdateRequest} from 'api/userApi'
import classNames from 'classnames'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import SelectBoldAnalysisVersion from 'components/Dialog/BoldSelect/SelectBoldAnalysisVersion'
import SelectBoldDepartment from 'components/Dialog/BoldSelect/SelectBoldDepartment'
import SelectBoldIndustryWrapper from 'components/Dialog/BoldSelect/SelectBoldIndustry'
import {nullCheck} from 'components/Dialog/User/Read'
import {
  closeSalesUserDetailInfoEditDialog,
  selectSalesUserEditDialogOpen,
  selectUserDialogType,
  selectUserDialogUid,
} from 'features/modal/modalSlice'
import useOrganization from 'features/org/useOrganization'
import useOrgUser from 'features/org/useOrgUser'
import {
  selectSales,
  updateSalesOrganizationUserAction,
} from 'features/sales/salesSlice'
import useInvoiceUsersOrg from 'features/invoice/useInvoiceUsersOrg'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import {ADMIN_INFO_DIALOG_TYPE} from 'constants/DialogConstant'
import {PASSWORD_SCHEME, REGEX_PHONE} from 'constants/CommonConstant'
import useStyles from 'components/Dialog/User/Style'
import styles from 'pages/OrgPage/UserPage/Style'

const Required = ({hidden = false}: {hidden?: boolean}) => {
  const classes = styles()
  const requiredClasses = classNames({
    [classes.requiredMark]: !hidden,
    [classes.requiredMarkHidden]: hidden,
  })
  return <Typography className={requiredClasses}>*</Typography>
}

const PinSchema = string()
  .matches(/^[0-9]+$/, 'IPinInvalid')
  .min(4, 'IPinInvalid')
  .max(4, 'IPinInvalid')
  .matches(/^\d+$/)

const TelSchema = string().notRequired().matches(REGEX_PHONE, 'ITelInvalid')

const EditSchema = object({
  uid: string().required('IDepartmentRequired'), // 리덕스에서 값 할당
  firstName: string()
    .required('INameRequired')
    .min(0, 'INameLength')
    .max(50, 'INameLength'),
  lastName: string()
    .required('INameRequired')
    .min(0, 'INameLength')
    .max(50, 'INameLength'),
  email: string()
    .required('IEmailRequired')
    .email('IEmailInvalid')
    .max(50, 'IEmailLength'),
  checkedEmail: string()
    .required()
    .oneOf([ref('email'), null], 'IEmailDuplicatePlease'),
  licenseNumber: string(),
  departmentId: string(),
  //   .required('validate.department_please'),
  userType: string(),
  //   .required('validate.department_please'),
  authority: string(),
  tel: TelSchema,
  divisionId: string(),
  positionId: string(),
  eegVersionId: string(),
  hrvVersionId: string(),
  previousPin: PinSchema,
  pin: PinSchema,
  pinConfirm: string().oneOf([ref('pin'), null], 'IPinSamePlease'),
  password: PASSWORD_SCHEME,
})

interface EditForm extends TypeOf<typeof EditSchema> {}

export default function SalesUserEditDialog() {
  const {t} = useTranslation()
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const dispatch = useAppDispatch()
  const open = useAppSelector(selectSalesUserEditDialogOpen)
  const uid = useAppSelector(selectUserDialogUid)
  const dialogType = useAppSelector(selectUserDialogType)
  const detailSales = useAppSelector(selectSales)
  const {organization, onFetch: onOrgnizationFetch} = useOrganization()
  const {onSearch: searchUsers, search} = useInvoiceUsersOrg()
  const detailIndustry = organization?.detailIndustry
  const {user: userDetailInfo, onFetch} = useOrgUser()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  // 전화번호 - 제거
  const replaceValue = userDetailInfo?.tel ?? ''
  const telValue = replaceValue.replace(/-/gi, '@')
  // 필터 리스트 map
  const fullArray = ['GENERAL', 'RESEARCH', 'SALES', 'CS']
  // eeg & hrv 셀렉트 박스
  const [eegSelectedValue, setEegSelectedValue] = React.useState<number>()
  const [hrvSelectedValue, setHrvSelectedValue] = React.useState<number>()
  const [fileSelected, setFileSelected] = React.useState<File>()
  const [divisionId, setDivisionId] = React.useState(0)
  const [positionId, setPositionId] = React.useState(0)
  const [division, setDivision] = React.useState<Industry>({
    id: 0,
    parent: 0,
    depth: 0,
    title: '',
  })
  const [position, setPosition] = React.useState<Industry>({
    id: 0,
    parent: 0,
    depth: 0,
    title: '',
  })
  const [departmentValue, setDepartmentValue] = React.useState('')
  const [authorityList, setAuthorityList] = React.useState([''])

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    reset,
  } = useForm<EditForm>({
    // @ts-ignore
    resolver: yupResolver(EditSchema),
  })
  const handleClose = () => dispatch(closeSalesUserDetailInfoEditDialog())
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    const reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/
    if (!fileList) return
    if (!fileList[0].name.match(reg)) {
      onFailureModalOpen(t('IImageInvalid'))
      return
    }
    setFileSelected(fileList[0])
  }

  const handleDivisionChanged = (industry: Industry) => {
    setDivision(industry)
    setDivisionId(industry.id ?? 0)
  }

  const handlePositionChanged = (industry: Industry) => {
    setPosition(industry)
    setPositionId(industry.id ?? 0)
  }

  const handleDepartmentValueChanged = (department: string) => {
    setValue('departmentId', department)
    setDepartmentValue(department)
  }

  const handleEEgValueChanged = (eegVersion: DataVersion) =>
    setEegSelectedValue(eegVersion.id)

  const handleHrvValueChanged = (hrvVersion: DataVersion) =>
    setHrvSelectedValue(hrvVersion.id)

  function makeHipenRemove(string: string): string {
    const telValue = string.replace(/[^0-9]/gi, '')
    return telValue
  }

  function makeAuthority(selected: string): string {
    if (userDetailInfo?.authority.includes('SHAREWARE')) {
      if (selected === 'GENERAL') {
        return 'ROLE_SHAREWARE_USER'
      }
      if (selected === 'RESEARCH') {
        return 'ROLE_SHAREWARE_RESEARCHER'
      }
      if (selected === null || selected === undefined || selected === '') {
        return 'ROLE_SHAREWARE_ADMIN'
      }
    }
    if (userDetailInfo?.authority.includes('ORGANIZATION')) {
      if (selected === 'GENERAL') {
        return 'ROLE_ORG_USER'
      }
      if (selected === 'RESEARCH') {
        return 'ROLE_ORG_RESEARCHER'
      }
    }
    return 'ROLE_UNKNOWN'
  }

  function checkAuthority(group: string) {
    if (group === 'COMPANY_GROUP') {
      setAuthorityList(fullArray)
    } else {
      setAuthorityList(fullArray)
    }
  }

  function checkAuthorityView(string: string | undefined): boolean {
    if (!string) {
      return false
    }
    if (dialogType === ADMIN_INFO_DIALOG_TYPE) {
      if (string.includes('ADMIN')) {
        return false
      }
      return true
    }
    return false
  }

  const authorityListMap = authorityList.map((value) => (
    <FormControlLabel
      value={value}
      control={<Radio color='primary' />}
      label={value}
      // className={classes.radioButtonTextColor}
    />
  ))

  const onSubmit = async (form: EditForm) => {
    const data: OrgUserUpdateRequest = {
      uid,
      firstName: form.firstName,
      lastName: form.lastName,
      // name: form.name,
      email: form.email,
      profile: fileSelected || null,
      licenseNumber: form.licenseNumber || null,
      divisionId: divisionId !== 0 ? divisionId : null,
      positionId: positionId !== 0 ? positionId : null,
      eegVersionId: eegSelectedValue ?? null,
      hrvVersionId: eegSelectedValue ?? null,
      // userType: form.userType,
      authority: makeAuthority(form.authority ?? ''),
      tel: form.tel || null,
      // TODO: department 수정 요청하기
      deptName: form.departmentId || null,
      // pin: form.pin || null,
      password: form.password || '',
    }

    if (dialogType !== ADMIN_INFO_DIALOG_TYPE) {
      delete data.authority
    }

    if (
      data.authority === 'ROLE_UNKNOWN' ||
      data.authority?.includes('ADMIN')
    ) {
      delete data.authority
    }

    // TODO: 영업관리 사용자 수정에서 first Name, last Name 수정시 수정이 안돼는 현상 수정해야함 (서버에 요청해야됨)
    await dispatch(
      updateSalesOrganizationUserAction({
        uid,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        profile: fileSelected || null,
        licenseNumber: form.licenseNumber || null,
        divisionId: divisionId !== 0 ? divisionId : null,
        positionId: positionId !== 0 ? positionId : null,
        eegVersionId: eegSelectedValue ?? null,
        hrvVersionId: eegSelectedValue ?? null,
        // userType: form.userType,
        authority: makeAuthority(form.authority ?? ''),
        tel: form.tel || null,
        // TODO: department 수정 요청하기
        deptName: form.departmentId || null,
        // pin: form.pin || null,
        password: form.password || '',
      }),
    )
    handleClose()
    onSuccessModalOpen(t('IProcessSuccess'))
    searchUsers(search)
  }

  useEffect(() => {
    if (open && uid) {
      onFetch(uid)
      onOrgnizationFetch()
    }
    return () => {
      reset()
    }
  }, [open, uid])

  useEffect(() => {
    if (open && uid) {
      setValue('uid', uid)
    }
  }, [open, uid])

  useEffect(() => {
    if (nullCheck(detailSales.searchedDetailData)) {
      checkAuthority(detailSales.searchedDetailData.orgDetail.authorityGroup)
    }
  }, [open, detailSales])

  useEffect(() => {}, [division, position])

  useEffect(() => {
    if (open && userDetailInfo) {
      setValue('firstName', userDetailInfo.firstName ?? '')
      setValue('lastName', userDetailInfo.lastName ?? '')
      setValue('email', userDetailInfo.email ?? '')
      setValue('checkedEmail', userDetailInfo.email ?? '')
      setValue('tel', makeHipenRemove(userDetailInfo.tel ?? ''))
      setValue('userType', userDetailInfo.userType ?? 'DOCTOR')
      setValue('licenseNumber', userDetailInfo.licenseNumber ?? '')
      // TODO: 사용자 목록에서 eegVersion 정보에 id 내려달라고 요청하기
      setDepartmentValue(userDetailInfo.deptName ?? '')
      setValue('departmentId', departmentValue)
      setValue('eegVersionId', String(eegSelectedValue))
      setValue('hrvVersionId', String(hrvSelectedValue))
      // setValue('divisionId', String(divisionId))
      // setValue('positionId', String(positionId))
      setDivisionId(userDetailInfo.division.id ?? '')
      setPositionId(userDetailInfo.position.id ?? '')
    }
  }, [open, userDetailInfo])

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth='md'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.root}>
            {/* 상단 타이틀 컨테이너  */}
            <div className={classes.longTitleContainer}>
              <div className={classes.containerTitle}>
                <div className={classes.closeButtonWrap}>
                  <IconButton
                    color='secondary'
                    aria-label='favorite'
                    className={classes.closeButton}
                    onClick={handleClose}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </div>
                <Typography className={classes.title}>
                  {t('IUserUpdate')}
                </Typography>
              </div>
            </div>

            <Divider className={classes.titleDivider} />
            <div className={classes.scroll}>
              {/* 컨텐츠 컨테이너 */}
              <div className={classes.contentContainer}>
                <Typography className={classes.subTitle}>
                  {t('IInstituteTitleName')}
                </Typography>
                <Typography className={classes.padding} />
                <Typography className={classes.padding} />
                <Typography className={classes.padding} />
                <Typography className={classes.subTitle}>
                  {userDetailInfo?.orgName ?? '-'}
                </Typography>
              </div>
              {/* 성명 */}
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}> {t('IName')} </p>
                  <p className={inputClasses.require}>*</p>
                </div>

                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Controller
                    name='firstName'
                    control={control}
                    rules={{required: false}}
                    render={({field}) => (
                      <div className={classes.inputWrap}>
                        <input
                          placeholder={t('IFirstName')}
                          defaultValue={userDetailInfo?.firstName ?? ''}
                          className={[
                            `${inputClasses.input}`,
                            `${errors.firstName ? classes.error : ''}`,
                          ].join(' ')}
                          {...field}
                        />
                      </div>
                    )}
                  />
                  <Typography className={classes.padding} />
                  <Controller
                    name='lastName'
                    control={control}
                    rules={{required: false}}
                    render={({field}) => (
                      <div className={classes.inputWrap}>
                        <input
                          placeholder={t('ILastName')}
                          defaultValue={userDetailInfo?.lastName ?? ''}
                          className={[
                            `${inputClasses.input}`,
                            `${errors.lastName ? classes.error : ''}`,
                          ].join(' ')}
                          {...field}
                        />
                      </div>
                    )}
                  />
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className={classes.inputWrap}>
                    <span className={classes.errorText}>
                      {errors.firstName && (
                        <div>{t(errors.firstName?.message ?? '')}</div>
                      )}
                    </span>
                  </div>
                  <Typography className={classes.padding} />{' '}
                  <div className={classes.inputWrap}>
                    <span className={classes.errorText}>
                      {errors.lastName && (
                        <div>{t(errors.lastName?.message ?? '')}</div>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}> {t('IEmail')} </p>
                  <p className={inputClasses.require}>*</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Controller
                    name='email'
                    control={control}
                    rules={{required: false}}
                    render={({field}) => (
                      <div className={classes.inputWrap}>
                        <input
                          id='email'
                          className={[
                            `${inputClasses.input}`,
                            `${errors.email ? classes.error : ''}`,
                          ].join(' ')}
                          disabled
                          {...field}
                        />
                        <span className={classes.errorText}>
                          {errors.email && (
                            <div>{t(errors.email?.message ?? '')}</div>
                          )}
                        </span>
                      </div>
                    )}
                  />
                </div>
              </div>

              <Typography className={classes.padding} />
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}> {t('IPassword')} </p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Controller
                    name='password'
                    control={control}
                    rules={{required: false}}
                    render={({field}) => (
                      <div className={classes.inputWrap}>
                        <input
                          type='password'
                          id='password'
                          placeholder={t('IPassword')}
                          className={[
                            `${inputClasses.input}`,
                            `${errors.password ? classes.error : ''}`,
                          ].join(' ')}
                          {...field}
                        />
                        <span className={classes.errorText}>
                          {errors.password && (
                            <div>{t(errors.password?.message ?? '')}</div>
                          )}
                        </span>
                      </div>
                    )}
                  />
                </div>
              </div>

              <Typography className={classes.padding} />
              <div className={classes.inputContainer}>
                <div className={inputClasses.labelWrapContainer}>
                  <p className={inputClasses.inputLabel}>
                    {t('IUserDepartment')}
                  </p>
                  <p className={inputClasses.require}>*</p>
                </div>
                <div className={classes.inputWrap}>
                  <SelectBoldDepartment
                    selectedValue={userDetailInfo?.deptName ?? ''}
                    onChangeData={handleDepartmentValueChanged}
                    // {...field}
                  />
                </div>
                <Typography>{t('IUserDepartmentDesc')}</Typography>
              </div>

              <Typography className={classes.padding} />
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>
                      {t('IUserProfile')}
                    </p>
                  </div>
                  <div className={classes.fileContainer}>
                    <div className={classes.fileWrap}>
                      <input
                        id='profile'
                        name='profile'
                        type='file'
                        // accept='.jpg, .jpeg, .png'
                        onChange={handleImageChange}
                        className={classes.fileInput}
                      />
                      <label className={classes.fileLabel} htmlFor='profile'>
                        <div style={{padding: 10}}>
                          {fileSelected ? fileSelected.name : t('IFileName')}
                        </div>
                        <div className={classes.iconContainer}>
                          <SearchIcon />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <Typography className={classes.padding} />
                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>{t('ITel')}</p>
                  </div>

                  <Controller
                    name='tel'
                    control={control}
                    render={({field}) => (
                      <div className={classes.inputWrap}>
                        <input
                          placeholder={t('ITel')}
                          defaultValue={makeHipenRemove(telValue)}
                          className={[
                            `${inputClasses.input}`,
                            `${errors.tel ? classes.error : ''}`,
                          ].join(' ')}
                          {...field}
                        />
                        <span className={classes.errorText}>
                          {errors.tel && (
                            <div>{t(errors.tel?.message ?? '')}</div>
                          )}
                        </span>
                      </div>
                    )}
                  />
                </div>
              </div>

              <Typography className={classes.padding} />
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>
                      {t('IUserDivision')}
                    </p>
                  </div>
                  <SelectBoldIndustryWrapper
                    parent={detailIndustry?.id}
                    depth={3}
                    defaultValue={userDetailInfo?.division.id}
                    selectedValue={userDetailInfo?.division.title ?? null}
                    onChangeData={handleDivisionChanged}
                  />
                </div>
                <Typography className={classes.padding} />
                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>
                      {t('IUserPosition')}
                    </p>
                  </div>
                  <SelectBoldIndustryWrapper
                    parent={divisionId}
                    depth={4}
                    defaultValue={userDetailInfo?.position.id}
                    selectedValue={userDetailInfo?.position.title ?? null}
                    onChangeData={handlePositionChanged}
                  />
                </div>
              </div>

              <Typography className={classes.padding} />
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>
                      NormDB EEG version
                    </p>
                  </div>
                  <SelectBoldAnalysisVersion
                    type='EEG'
                    selectedValue={
                      nullCheck(userDetailInfo?.eegVersion)
                        ? userDetailInfo?.eegVersion.version ?? ''
                        : null
                    }
                    onChangeData={handleEEgValueChanged}
                    // {...field}
                  />
                </div>
                <Typography className={classes.padding} />
                <div className={classes.inputContainer}>
                  <div className={inputClasses.labelWrapContainer}>
                    <p className={inputClasses.inputLabel}>
                      NormDB HRV version
                    </p>
                  </div>

                  <SelectBoldAnalysisVersion
                    type='HRV'
                    selectedValue={
                      nullCheck(userDetailInfo?.hrvVersion)
                        ? userDetailInfo?.hrvVersion.version ?? ''
                        : null
                    }
                    onChangeData={handleHrvValueChanged}
                    // {...field}
                  />
                </div>
              </div>

              <Typography className={classes.padding} />
              {checkAuthorityView(userDetailInfo?.authority) && (
                <div className={classes.contentContainer}>
                  <Required hidden />
                  <Typography className={classes.subTitle}>
                    {t('IAuthority')}
                  </Typography>
                  <Controller
                    rules={{required: false}}
                    name='authority'
                    control={control}
                    // defaultValue='General'
                    render={({field}) => (
                      <RadioGroup row aria-label='userType' {...field}>
                        {authorityListMap}
                      </RadioGroup>
                    )}
                  />
                </div>
              )}

              <Typography className={classes.padding} />
              {checkAuthorityView(userDetailInfo?.authority) && (
                <Divider className={classes.line} />
              )}
              <Typography className={classes.padding} />
              <Typography className={classes.padding} />
            </div>
            {/* 하단 버튼 컨테이너  */}
            <div className={classes.buttonContainerBgColor}>
              <div className={classes.buttonContainer}>
                <Button
                  variant='contained'
                  color='default'
                  disableElevation
                  onClick={handleClose}
                  className={classes.okButton}
                >
                  {t('IDiscardEdit')}
                </Button>
                <Typography className={classes.padding} />
                <Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  type='submit'
                  className={classes.okButtonColor}
                >
                  {t('IOk')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  )
}
