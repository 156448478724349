import {useTranslation} from 'react-i18next'
import {useAppDispatch, useAppSelector} from 'hooks'
import useFailureModal from 'features/modal/useFailureModal'
import {SearchAllRequest} from 'api/salesApi'
import {
  searchSalesOrgUser,
  selectError,
  selectLoading,
  selectQuery,
  selectSearch,
  selectPaging,
  selectPagingInfo,
  selectList,
} from './invoiceUsersOrgSlice'

export default function useInvoiceUsersOrg() {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {onOpen: onFailureModalOpen} = useFailureModal()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const items = useAppSelector(selectList)
  const paging = useAppSelector(selectPaging)
  const search = useAppSelector(selectSearch)
  const query = useAppSelector(selectQuery)
  const pagingInfo = useAppSelector(selectPagingInfo)

  const onSearch = async (payload: SearchAllRequest) => {
    const res = await dispatch(searchSalesOrgUser(payload)).unwrap()
    if (!res.content.length) {
      onFailureModalOpen(t('ISearchORGEmpty'))
    }
  }

  return {
    loading,
    error,
    items,
    search,
    paging,
    query,
    pagingInfo,
    onSearch,
  }
}
